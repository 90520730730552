import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import BootstrapVue from "bootstrap-vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGlassWaterDroplet, fas } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import HomeView from "@/views/HomeView.vue";
import priceView from "@/views/PriceView.vue";
import impressumView from "@/views/ImpressumView.vue";
import datenschutzView from "@/views/DatenschutzView.vue";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueRouter);
library.add(faInstagram, faFacebook);
library.add(fas, faGlassWaterDroplet);
Vue.component("font-awesome-icon", FontAwesomeIcon);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/preise",
    name: "preise",
    component: priceView,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: impressumView,
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: datenschutzView,
  },
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
