import { render, staticRenderFns } from "./LocationComp.vue?vue&type=template&id=206206ba&scoped=true"
var script = {}
import style0 from "./LocationComp.vue?vue&type=style&index=0&id=206206ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206206ba",
  null
  
)

export default component.exports