

<template>
  <section class="container-fluid slide-Section" id="studio">

    <div v-show="isSlideModalShowen" id="slideModal" class="slideModal">
      <button @click="hideModal" class="close">&times;</button>
      <img class="slide-modal-content" id="slideModalImage" :src="slideSources[0].src">
    </div>

    <div class="row slide-show-row">

      <div class="col middle-image slideshow-container">

        <div class="mySlides">
          <img :src="currentImage" @click="showModal"/>
        </div>

        <!-- Next and previous buttons -->
        <div class="prev" v-on:click="plusSlides(-1)">
          <font-awesome-icon icon="fa-solid fa-chevron-left" class="icon"/>
        </div>
        <div class="next" v-on:click="plusSlides(1)">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="icon"/>
        </div>
      </div>



    </div>
    <div class="row image-gallery"  v-show="!mobile">
      <div class="col-1 arrow left">
        <div class="prev" v-on:click="scrollToOffset(-200)">
          <font-awesome-icon icon="fa-solid fa-chevron-left" class="icon"/>
        </div>
      </div>
      <div class="col-10 thumbnail-list" id="slide">
      <div v-for="(slide, index) in this.slideSources"
           :key="index"
           class="div-thumbnail">
        <img :src=slide.src class="thumbnail"
             :data-index="index"
             @click="changeSlide">
      </div>
      </div>
      <!-- Next and previous buttons -->


      <div class="col-1 arrow right">
        <div class="next" v-on:click="scrollToOffset(200)">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="icon"/>
        </div>
      </div>

    </div>
  </section>

</template>


<style lang="scss" scoped>

@media only screen and (max-width: 992px) {
  .slide-Section{
    margin: 0;
    padding: 50px 0;
    width: 100%;
    height: 500px;
    display: block;
    justify-content: center;
    overflow: hidden;

    .slide-show-row{
      height: 100%;
      margin: 0;
      width: 100%;
      overflow: hidden;
      .slideshow-container{
        padding: 0;
        max-width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
      }

      .mySlides {
        display: block;
        height: 100%;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: 30px;
        height: 30px;
        font-size: 18px;
        transition: 0.6s ease;
        font-weight: lighter;
        border-radius: 5px;
        user-select: none;
        margin: auto;
        display: flex;
        justify-content: center;

        .icon{
          width: 100%;
          height: 100%;
          color: #FF7517;

        }
      }
      .next {
        right: 3%;
      }
      .prev{
        left: 3%;
      }

      .prev:hover, .next:hover {
        background-color: rgba(0,0,0,0.8);
      }


    }
  }
}

@media only screen and (min-width: 992px) {
  .slide-Section{
    margin: 0;
    padding: 50px 0;
    width: 100%;
    height: 800px;
    display: block;
    justify-content: center;
    overflow: hidden;

    .slide-show-row{
      height: 80%;
      width: 100%;
      overflow: hidden;
      margin: 0 auto 10px;

      .slideshow-container{
        max-width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
      }

      .mySlides {
        display: block;
        height: 100%;
        img{
          border-radius: 5px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: 50px;
        height: 50px;
        font-size: 18px;
        transition: 0.6s ease;
        font-weight: lighter;
        border-radius: 5px;
        user-select: none;
        margin: auto;
        display: flex;
        justify-content: center;

        .icon{
          width: 100%;
          height: 100%;
          color: #FF7517;

        }
      }
      .next {
        right: 3%;
      }
      .prev{
        left: 3%;
      }

      /* On hover, add a black background color with a little bit see-through */
      .prev:hover, .next:hover {
        background-color: rgba(0,0,0,0.8);
      }

    }

    .image-gallery{
      width: 95%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .left{
        display: flex;
        justify-content: right;
      }
      .right{
        display: flex;
        justify-content: left;
      }
      .arrow{
        .prev, .next {
          cursor: pointer;
          width: 30px;
          height: 30px;
          font-size: 25px;
          border-radius: 5px;
          user-select: none;

          .icon{
            width: 100%;
            height: 100%;
            color: #FF7517;

          }
        }
        .prev:hover, .next:hover {
          background-color: rgba(0,0,0,0.8);
        }

      }

      .thumbnail-list {
        flex-wrap: nowrap;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        overflow:hidden;
        scroll-behavior: smooth;

        .div-thumbnail {
          height: 100%;
          padding: 30px 10px;
          aspect-ratio: 1/1;


          .thumbnail {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 5px;
          }
        }
        .highlighted{
          border: 2px solid #FF7517;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .slide-Section{
    margin: 0;
    padding: 50px 0;
    width: 100%;
    height: 800px;
    display: block;
    justify-content: center;
    overflow: hidden;

    .slide-show-row{
      height: 80%;
      width: 100%;
      overflow: hidden;
      margin-bottom: 10px;
      .slideshow-container{
        max-width: 70%;
        height: 100%;
        position: relative;
        margin: auto;
      }

      .mySlides {
        display: block;
        height: 100%;
        img{
          border-radius: 5px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: 50px;
        height: 50px;
        font-size: 18px;
        transition: 0.6s ease;
        font-weight: lighter;
        border-radius: 5px;
        user-select: none;
        margin: auto;
        display: flex;
        justify-content: center;

        .icon{
          width: 100%;
          height: 100%;
          color: #FF7517;

        }
      }
      .next {
        right: 3%;
      }
      .prev{
        left: 3%;
      }

      /* On hover, add a black background color with a little bit see-through */
      .prev:hover, .next:hover {
        background-color: rgba(0,0,0,0.8);
      }


    }

    .image-gallery{
      width: 95%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .left{
        display: flex;
        justify-content: right;
      }
      .right{
        display: flex;
        justify-content: left;
      }
      .arrow{
        .prev, .next {
          cursor: pointer;
          width: 30px;
          height: 30px;
          font-size: 25px;
          border-radius: 5px;
          user-select: none;

          .icon{
            width: 100%;
            height: 100%;
            color: #FF7517;

          }
        }
        .prev:hover, .next:hover {
          background-color: rgba(0,0,0,0.8);
        }

      }

      .thumbnail-list {
        flex-wrap: nowrap;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        overflow:hidden;
        scroll-behavior: smooth;

        .div-thumbnail {
          height: 100%;
          padding: 30px 10px;
          aspect-ratio: 1/1;


          .thumbnail {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 5px;
          }
        }
        .highlighted{
          border: 2px solid #FF7517;
        }
      }
    }
  }
}

@media only screen and (min-width: 1921px) {
  .slide-Section{
    margin: 0;
    padding: 50px 0;
    width: 100%;
    height: 1000px;
    display: block;
    justify-content: center;
    overflow: hidden;

    .slide-show-row{
      height: 85%;
      width: 100%;
      overflow: hidden;
      margin-bottom: 10px;
      .slideshow-container{
        max-width: 70%;
        height: 100%;
        position: relative;
        margin: auto;
      }

      .mySlides {
        display: block;
        height: 100%;
        img{
          border-radius: 5px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 45%;
        width: 50px;
        height: 50px;
        font-size: 18px;
        transition: 0.6s ease;
        font-weight: lighter;
        border-radius: 5px;
        user-select: none;
        margin: auto;
        display: flex;
        justify-content: center;

        .icon{
          width: 100%;
          height: 100%;
          color: #FF7517;

        }
      }
      .next {
        right: 3%;
      }
      .prev{
        left: 3%;
      }

      /* On hover, add a black background color with a little bit see-through */
      .prev:hover, .next:hover {
        background-color: rgba(0,0,0,0.8);
      }


    }

    .image-gallery{
      width: 95%;
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .left{
        display: flex;
        justify-content: right;
      }
      .right{
        display: flex;
        justify-content: left;
      }
      .arrow{
        .prev, .next {
          cursor: pointer;
          width: 30px;
          height: 30px;
          font-size: 25px;
          border-radius: 5px;
          user-select: none;

          .icon{
            width: 100%;
            height: 100%;
            color: #FF7517;

          }
        }
        .prev:hover, .next:hover {
          background-color: rgba(0,0,0,0.8);
        }

      }

      .thumbnail-list {
        flex-wrap: nowrap;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        overflow:hidden;
        scroll-behavior: smooth;

        .div-thumbnail {
          height: 100%;
          padding: 30px 10px;
          aspect-ratio: 1/1;


          .thumbnail {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 5px;
          }
        }
        .highlighted{
          border: 2px solid #FF7517;
        }
      }
    }
  }
}

.slideModal {
  display: flex;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}
.slide-modal-content {
  top: 50%;
  margin: auto;
  display: block;
  width: 80%;
  max-width: 90vh;
}
.close {
  all: unset;
  position: absolute;
  top: 10%;
  right: 35px;
  color: #f1f1f1;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

</style>


<script>
export default {
  name: "SlidingShowComp",
  data() {
    return {
      slideSources: [
        {
          src: require("../assets/images/studio/IMG_6754.jpg"),
        },
        {
          src: require("../assets/images/studio/crosstrainer.jpg"),
        },
        {
          src: require("../assets/images/studio/getraenkeautomat1.jpg"),

        },
        {
          src: require("../assets/images/studio/getraenkeautomat2.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumoben1.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumoben3.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten2.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten3.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten6.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten8.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten10.jpg"),

        },
        {
          src: require("../assets/images/studio/trainingsraumunten11.jpg"),

        },
        {
          src: require("../assets/images/studio/solarium.jpg"),

        },
        {
          src: require("../assets/images/studio/kursraum.jpg"),

        },
        {
          src: require("../assets/images/studio/kursraum2.jpg"),

        },
        {
          src: require("../assets/images/studio/kampsport3.jpg"),

        },
        {
          src: require("../assets/images/studio/kampfsport2.jpg"),

        },
      ],
      slideIndex: 0,
      currentImage: null,
      mobile: false,
      slides: null,
      thumbnails: null,
      slideModal: null,
      imageSlideModal: null,
      isSlideModalShowen: false,
    }
  },
  methods: {
    scrollToOffset(n){
        document.getElementById("slide").scrollLeft += n;
    },
    plusSlides(n){
      this.showSlides(this.slideIndex + n);
    },
    showModal(){
      this.imageSlideModal.src = this.slideSources[this.slideIndex].src;
      this.isSlideModalShowen = true

    },
    hideModal(){
      this.isSlideModalShowen = false
    },
    showSlides(n) {
      if (n >= 0 && n < this.slideSources.length) {

        this.thumbnails[this.slideIndex].classList.remove("highlighted");
        this.slideIndex = n
        this.currentImage = this.slideSources[this.slideIndex].src

        this.thumbnails[this.slideIndex].classList.add("highlighted")
      }
    },
    changeSlide(event){
      if(event){
        let index = event.target.getAttribute("data-index");
        this.showSlides(index)
      }
      },
    checkMobile() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 992) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    }
    },
  created(){
    this.currentImage = this.slideSources[this.slideIndex].src
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
    this.thumbnails = document.getElementsByClassName('thumbnail');

  },
  mounted() {
    this.slideModal = document.getElementById("slideModal");
    this.imageSlideModal = document.getElementById("slideModalImage");
    this.thumbnails[this.slideIndex].classList.add("highlighted");
  }


}
</script>
