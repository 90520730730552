<script>
import NavigationbarComp from "@/components/NavigationbarComp.vue";
import FourImagesComp from "@/components/FourImagesComp.vue";
import ZeitenComp from "@/components/ZeitenComp.vue";
import SaunaComp from "@/components/SaunaComp.vue";
import LocationComp from "@/components/LocationComp.vue";
import SlideShowComp from "@/components/SlidingShowComp.vue";
import PartnerComp from "@/components/PartnerComp.vue";
import BenefitsComp from "@/components/BenefitsComp.vue";
import FooterComp from "@/components/FooterComp.vue";
export default {
  components: {
    PartnerComp,
    SlideShowComp,
    LocationComp,
    SaunaComp,
    ZeitenComp,
    NavigationbarComp,
    FourImagesComp,
    BenefitsComp,
    FooterComp,
  }
}
</script>

<template>
  <main class="homeview">
    <NavigationbarComp />
    <body>
    <section class="container-fluid homeview-div" id="home">
      <div class="homeView-content">
        <img class="background" src="../assets/images/stocks/background.jpg" alt="background_image"/>
        <img class="logo" src="../assets/images/logo/caprice_logo.png" alt="caprice_logo">
        <h1 class="homeView-title">NO PAIN NO GAIN</h1>+
      </div>
    </section>
    <FourImagesComp />
    <ZeitenComp />
    <SlideShowComp/>
    <benefits-comp/>
    <sauna-comp/>
    <location-comp/>
    <PartnerComp/>
    </body>
    <FooterComp/>

  </main>
</template>

<style scoped>

body {
  height: 100%;
  background-color: #0D0E14;
}

@media only screen and (min-width: 1300px)
{
  .homeview-div {
    display: block;
    padding: 0;
    width: 100%;
    .homeView-content {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;


      .background{
        height: 100vh;
        width: 100%;
        margin: 0;
        position: absolute;
        object-position: center;
        object-fit: cover;

      }

      .homeView-title {
        margin: auto;
        color: white;
        font-family: "Alfa Slab One", serif;
        font-size: 100px;
        text-align: center;
        letter-spacing: 0.1em;
        z-index: 2;
        position: absolute;
      }

      .logo {
        max-height: 80%;
        max-width: 100%;
        position: absolute;
        margin: auto;

      }
    }
  }
}

  @media only screen and (max-width: 1300px)
{
    .homeview-div {
      display: block;
      padding: 0;
      width: 100%;

      .homeView-content {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;


        .background{
          height: 100vh;
          width: 100%;
          margin: 0;
          position: absolute;
          object-position: center;
          object-fit: cover;

        }

        .homeView-title {
          margin: auto;
          color: white;
          font-family: "Alfa Slab One", serif;
          font-size: 80px;
          text-align: center;
          letter-spacing: 0.1em;
          z-index: 2;
        }

        .logo {
          max-height: 80vh;
          max-width: 100%;
          position: absolute;
          margin: auto;

        }
      }
    }
  }

  @media only screen and (max-width: 900px)
{
    .homeview-div {
      display: block;
      width: 100%;
      padding: 0;
      .homeView-content {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;


        .background{
          height: 100vh;
          width: 100%;
          margin: 0;
          position: absolute;
          object-position: center;
          object-fit: cover;

        }

        .homeView-title {
          margin: auto;
          color: white;
          font-family: "Alfa Slab One", serif;
          font-size: 50px;
          text-align: center;
          letter-spacing: 0.1em;
          z-index: 2;
        }

        .logo {
          max-height: 80vh;
          max-width: 100%;
          position: absolute;
          margin: auto;

        }
      }
    }
}

  @media only screen and (max-width: 600px)
{
    .homeview-div {
      display: block;
      padding: 0;
      width: 100%;
      .homeView-content {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;


        .background{
          height: 100vh;
          width: 100%;
          margin: 0;
          position: absolute;
          object-position: center;
          object-fit: cover;

        }

        .homeView-title {
          margin: auto;
          color: white;
          font-family: "Alfa Slab One", serif;
          font-size: 50px;
          text-align: center;
          letter-spacing: 0.1em;
          z-index: 2;
        }

        .logo {
          max-height: 80vh;
          max-width: 100%;
          position: absolute;
          margin: auto;

        }
      }
    }
  }





</style>