<script>


import {defineComponent} from "vue";
import NavigationbarComp from "@/components/NavigationbarComp.vue";
import PartnerComp from "@/components/PartnerComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default defineComponent({
  components: {FooterComp, PartnerComp, NavigationbarComp}
})
</script>

<template>

  <main class="impressumView">
    <NavigationbarComp />
    <section class="impressumSection container" id="impressum">
    <h1 class="impressumTitle">Impressum</h1>
    <div class="Diensteanbieter">
      <h3 class="impressumCategory">Diensteanbieter</h3>
      <p class="impressumDescrip"> Caprice Fitness Club</p>
      <p class="impressumDescrip">Team Sport Management GmbH & Co. KG</p>
      <p class="impressumDescrip">Billstedter Hauptstraße 1-15</p>
      <p class="impressumDescrip">22111 Hamburg</p>
    </div>

    <div class="kontakt">
      <h3 class="impressumCategory">Kontaktmöglichkeiten</h3>
      <p class="impressumDescrip">E-MailAdress: info@caprice-fitness.de</p>
      <p class="impressumDescrip">Telefon: 040 73675751</p>
      <p class="impressumDescrip">Fax: 040 73675752</p>
    </div>

    <div class="inhaber">
      <h3 class="impressumCategory">Geschäftsführer</h3>
      <p class="impressumDescrip">Hidayet Sakarya</p>
    </div>

    <div class="Rechtsform">
      <h3 class="impressumCategory">Rechtsform</h3>
      <p class="impressumDescrip">GmbH</p>
    </div>

    <div class="Registergericht">
      <h3 class="impressumCategory">Registergericht</h3>
      <p class="impressumDescrip">Amtsgericht Hamburg</p>
    </div>

    <div class="Registernummer">
      <h3 class="impressumCategory">Registernummer</h3>
      <p class="impressumDescrip">HRA 120404</p>
    </div>

    <div class="unternehmenssitz">
      <h3 class="impressumCategory">Sitz des Unternehmens</h3>
      <p class="impressumDescrip">Hamburg</p>
    </div>

    <div class="Verantwortlicher">
      <h3 class="impressumCategory">Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV</h3>
      <p class="impressumDescrip">Hidayet Sakarya (Adresse siehe oben)</p>
    </div>

    <p class="impressumDescrip">Alle Grafiken, Fotos und sonstige Unterlagen sind urheberrechtlich geschützt!</p>
    <p class="impressumDescrip"> Die Weitergabe oder Vervielfältigung ist ohne unser schriftliches Einverständnis
      ausdrücklich ausgeschlossen.</p>


    <h2 class="impressumCategory">Bildnachweise</h2>
      <p><a href="https://www.freepik.com/free-photo/solid-concrete-wall-textured-backdrop_17839221.htm#from_view=detail_alsolike">Image by rawpixel.com</a> on Freepik</p>
      <p><a href="https://www.pexels.com/de-de/foto/frauen-fitness-ubung-dehnen-3984363/">Image by pexels.com</a> on Pexels</p>
      <p><a href="https://www.pexels.com/de-de/foto/mann-person-athlet-ausbildung-9944846/">Image by pexels.com</a> on Pexels</p>
      <p><a href="https://www.pexels.com/de-de/foto/drei-frauen-in-verschiedenfarbigen-sport-b-hs-die-ihre-smartphones-halten-und-beobachten-905336/">Image by pexels.com</a> on Pexels</p>
    </section>
    <partner-comp/>
    <footer-comp/>
  </main>
</template>

<style scoped>


.impressumSection {
  text-align: left;
  padding-top: 150px;
  padding-bottom: 50px;

  .impressumTitle{
    color: #FF7517;
    font-size: 40px;
  }
  .Diensteanbieter, .kontakt, .inhaber, .Rechtsform, .Registergericht, .Registernummer, .unternehmenssitz, .Verantwortlicher, .impressumDescrip {
    margin: 30px 0;
    text-align: left;
  }

  .impressumCategory {
    font-family: Arial, serif;
    font-weight: bold;
    color: #FF7517;
    font-size: 25px;
    margin: 5px 0;
  }

  .impressumDescrip {
    font-family: Arial, serif;
    color: white;
    font-size: 15px;
    margin: 5px 0;
  }


}
</style>