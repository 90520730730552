<template>
  <section class="four-image-section">
    <div v-for="(image, index) in images" :key="index" class="image-wrapper">
      <img :alt="image.alt" :src="image.src" class="bild" />
      <div class="text-overlay">{{ image.text }}</div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          src: require("../assets/images/stocks/kurs.jpg"),
          alt: "Kurse",
          text: "Kurse",
        },
        {
          src: require("../assets/images/stocks/sauna.jpg"),
          alt: "Wellness",
          text: "Wellness",
        },
        {
          src: require("../assets/images/stocks/ladiesArea.jpg"),
          alt: "Ladies Area",
          text: "Ladies Area",
        },
        {
          src: require("../assets/images/stocks/kampfsport.jpg"),
          alt: "Kampfsport",
          text: "Kampfsport",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Ensure the component takes up the full width of the page */
.four-image-section {
  width: 100%;
  margin: 0;
  height: 500px; /* Set a fixed height for consistent aspect ratio */
  overflow: hidden; /* Hide any potential horizontal overflow */
  display: flex;
  padding: 0;
  padding-bottom: 50px;

  .image-wrapper {
    position: relative;
    flex: 1;
    height: 0; /* Set initial height to 0 to use padding-bottom trick */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */

    .bild {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%; /* Set a fixed height for the images */
      filter: brightness(0.7);
    }

    /* Center the text overlay within the image wrapper */

    .text-overlay {
      font-family: "Archivo", sans-serif;
      font-weight: bold;
      font-size: 40px; /* Adjust the font size as needed */
      color: white; /* Set the color of the text */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: brightness(1);
      text-align: center;
    }
  }
}

@media screen and (max-width: 499px) {
  .four-image-section {
    height: auto; /* Remove fixed height to let the grid adjust its height automatically */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split images into 2 columns with equal width */
    padding: 0;
    padding-bottom: 50px;

    .image-wrapper {
      height: 300px;

      .bild {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(
          0.7
        ); /* Adjust the brightness for the images on small screens */
      }

      .text-overlay {
        font-size: 24px; /* Adjust the font size for the text on small screens */
      }
    }
  }
}

/* Add media query for small screens */
@media screen and (min-width: 500px) and (max-width: 768px) {
  .four-image-section {
    height: auto; /* Remove fixed height to let the grid adjust its height automatically */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split images into 2 columns with equal width */
    padding: 0;
    padding-bottom: 50px;

    .image-wrapper {
      height: 400px;

      .bild {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(
          0.7
        ); /* Adjust the brightness for the images on small screens */
      }

      .text-overlay {
        font-size: 30px; /* Adjust the font size for the text on small screens */
      }
    }
  }
}

/* Add other media queries as needed */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .four-image-section {
    height: 400px;
    padding: 0;
    padding-bottom: 50px;

    .image-wrapper {
      height: 400px; /* Set a medium height for the image wrapper on medium screens */

      .text-overlay {
        font-size: 30px; /* Adjust the font size for medium-sized screens */
      }
    }
  }
}

/* You can add more breakpoints as needed for larger screens */
@media screen and (min-width: 1025px) {
  .four-image-section {
    height: 650px;
    padding: 0;
    padding-bottom: 50px;

    .image-wrapper {
      height: 650px; /* Set a larger height for the image wrapper on larger screens */

      .text-overlay {
        font-size: 40px; /* Restore the original font size for larger screens */
      }
    }
  }
}
</style>
