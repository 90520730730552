<template>
  <div id="preise" class="container-fluid homeview-div">
    <NavigationbarComp />
    <div class="priceContent">
      <TariffPricesComp />
      <AdditionalPriceInfoComp />
    </div>
    <BenefitsListComp />
    <location-comp />
    <PartnerComp />
    <FooterComp />
  </div>
</template>

<script>
import NavigationbarComp from "@/components/NavigationbarComp.vue";
import TariffPricesComp from "@/components/PricePage/TariffPricesComp.vue";
import BenefitsListComp from "@/components/PricePage/BenefitsListComp.vue";
import AdditionalPriceInfoComp from "@/components/PricePage/AdditionalPriceInfoComp.vue";
import LocationComp from "@/components/LocationComp.vue";
import PartnerComp from "@/components/PartnerComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  components: {
    FooterComp,
    PartnerComp,
    LocationComp,
    NavigationbarComp,
    TariffPricesComp,
    BenefitsListComp,
    AdditionalPriceInfoComp,
  },
  data() {
    return {
      benefits: [
        "Jumping Fitness",
        "Ausdauertraining",
        "Separater Frauenbereich",
        "Getränkeflat",
        "Sauna",
        "Kostenlose monatliche Körperanalyse",
        "Gesundheitsplanung",
        "Trainingsberatung",
        "Free WiFi",
        "Kostenlose Parkplätze",
      ],
    };
  },
  methods: {
    handleClick() {},
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  background: #0d0e14;
}

.priceContent {
  background-image: url("../assets/images/stocks/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;
  padding-top: 20px;
  width: 100%;
}

#preise{
  padding: 0;
}
</style>