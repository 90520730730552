<script>

export default {
  name: "FooterComp",
  data() {
    return {
    }
  },


  methods: {

    async scrollToElement(id, route) {

      if (route === this.$router.currentRoute.name){
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
      } else {
        await new Promise((resolve) => {
          this.$router.push({name: route})
          resolve()
        });
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
      }

    },
  },
}
</script>

<template>

  <footer class="footer">
    <div class="line">

    </div>
    <div class="row">
      <div class="col-4 capriceCopyRight">
        <p class="copyRightText">@Caprice Fitness Club Hamburg</p>
      </div>
      <div class="col-4 socialMedia">
        <a class="socialMediaBtb" href="https://www.instagram.com/capricefitnessclub/">
          <font-awesome-icon icon="fa-brands fa-instagram" class="icon"/>
        </a>
        <a class="socialMediaBtb" href="https://www.facebook.com/CapriceFitnessclub/">
          <font-awesome-icon icon="fa-brands fa-facebook" class="icon"/>
        </a>
      </div>
      <div class="col-4 impressumButton">
        <button class="buttonForImpressum" @click="scrollToElement('impressum', 'impressum')">Impressum</button>
        <button class="buttonForImpressum" @click="scrollToElement('datenschutz', 'datenschutz')">Datenschutz</button>
      </div>
    </div>
  </footer>
</template>

<style scoped>

@media screen and (min-width: 1000px) {
  .footer {
    padding-bottom: 10px;
    .line {
      margin: auto;
      height: 1px;
      width: 87vw;
      border-bottom: 1px #a8a8a8 solid;
    }

    .row {
      width: 87vw;
      padding: 10px;
      margin: auto;
      color: white;

      .capriceCopyRight {
        display: flex;
        justify-content: left;
        .copyRightText {
          all: unset;
          font-size: 15px;
          font-family: Arial, serif;
          margin: auto 0;
        }
      }

      .socialMedia {

        .socialMediaBtb {
          all: unset;
          margin: auto 15px;
          font-size: 25px;
          color: white;
        }
        .socialMediaBtb:hover {
          color: #FF7517;
        }

      }

      .impressumButton {
        display: flex;
        justify-content: right;

        .buttonForImpressum {
          all: unset;
          margin: 0 5px;
          font-size: 15px;
          font-family: Arial, serif;
          color: white;

        }
        .buttonForImpressum:hover{
          color: #FF7517;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .footer {
    padding-bottom: 10px;
    .line {
      margin: auto;
      height: 1px;
      width: 87vw;
      border-bottom: 1px #a8a8a8 solid;
    }

    .row {
      width: 87vw;
      padding: 10px;
      margin: auto;
      color: white;
      display: flex;

      .capriceCopyRight {
        display: flex;
        justify-content: left;
        .copyRightText {
          all: unset;
          font-size: 15px;
          font-family: Arial, serif;
          margin: auto 0;
        }
      }

      .socialMedia {
        margin: auto;
        flex-wrap: wrap;
        .socialMediaBtb {
          all: unset;
          margin: auto 15px;
          font-size: 20px;
          color: white;
        }
        .socialMediaBtb:hover {
          color: #FF7517;
        }

      }

      .impressumButton {
        display: flex;
        justify-content: right;
        flex-wrap: wrap;
        .buttonForImpressum {
          all: unset;
          margin: 0 5px;
          font-size: 15px;
          font-family: Arial, serif;
          color: white;


        }
        .buttonForImpressum:hover{
          color: #FF7517;
        }
      }
    }
  }
}
</style>