<template>
  <header>
    <div class="container-fluid container-nav navbar-color" v-show="!mobile">
      <div class="row">
        <div class="logo-div col-4">
          <img class="nav-logo" src="../assets/images/logo/caprice_logo.png" alt="Logo" @click="scrollToElement('home', 'home')"/>
          <h1 class="nav-title" @click="scrollToElement('home', 'home')">CAPRICE</h1>
        </div>

        <nav class="navigation-right navigation col-8">
          <ul>
            <li>
              <button @click="scrollToElement('home', 'home')">Home</button>
            </li>
            <li>
              <button @click="scrollToElement('studio', 'home')">Studio</button>
            </li>
            <li>
              <button @click="scrollToElement('sauna', 'home')">Wellness</button>
            </li>
            <li>
              <button @click="scrollToElement('preise', 'preise')">Preise</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>


    <div class="container-fluid container-nav minimal-nav navbar-mobile-color" v-show="mobile" >
      <div class="row minimal-row">

        <div class="logo-div col-8">
          <img class="nav-logo" src="../assets/images/logo/caprice_logo.png" alt="Logo" @click="scrollToElement('home', 'home')"/>
          <h1 class="nav-title" @click="scrollToElement('home', 'home')">CAPRICE</h1>
        </div>

        <div class="icon col-4">
          <font-awesome-icon icon="bars" class="bar-icon" @click="toogleMobileNav"/>
        </div>
      </div>
      <div class="mobileList" v-show="mobileNav">
        <ul>
          <li>
            <button @click="scrollToElement('home', 'home')">Home</button>
          </li>
          <li>
            <button @click="scrollToElement('studio', 'home')">Studio</button>
          </li>
          <li>
            <button @click="scrollToElement('sauna', 'home')">Wellness</button>
          </li>
          <li>
            <button @click="scrollToElement('preise', 'preise')">Preise</button>
          </li>
        </ul>
      </div>
    </div>




  </header>
</template>

<style lang="scss" scoped>



.navbar-color{
  background-color: rgba(0,0,0,0);
}
.navbar-color.solid{
  background-color: #191A1F;
}

.navbar-mobile-color{
  background-color: rgba(0,0,0,0);
}
.navbar-mobile-color.solid{
  background-color: #191A1F;
}
.minimal-nav {
  height: 80px !important;

    .mobileList{
      background-color: #191A1F;
      width: 100%;
      height: auto;
      padding: 20px 10px;

      ul {
        height: 100%;

        li {
          display: flex;

          button {
            all: unset;
            color: white;

            position: relative;
            font-family: "Archivo", sans-serif;
            font-size: 20px;
            margin: 10px 0;

          }

          button:hover {
            color: #FF7517;
            border-bottom: 1px solid #FF7517;


          }
        }
      }
    }

}
.container-nav {
  padding: 10px 0;
  z-index: 98;
  height: 90px;
  width: 100%;
  top: 0;
  position: fixed;
  transition: .5s ease all;

  .row {
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .navigation-right {
      justify-content: left;
    }

    .navigation {
      height: 100%;
      display: flex;
      flex-grow: 1;
      max-width: 100%;
      padding-right: 50px;
      white-space: nowrap;
      justify-content: right;


      ul {
        padding: 0;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        list-style: none;
        margin-bottom: 0;

        li {
          display: flex;
          padding: 0 30px;

          button{
            all: unset;
            margin: auto 0;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-family: "Archivo", sans-serif;
            font-size: 18px;
            padding: 10px;

          }
          button:hover{
            color: #FF7517;
            border-bottom: 1px solid #FF7517;


          }
        }
      }
    }

    .logo-div {
      height: 100%;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 50px;
      display: flex;
      justify-content: left;

      .nav-logo {
        height: 100%;
        width: auto;
        margin-right: 5px;
      }
      .nav-logo:hover{
        cursor: pointer;
      }

      .nav-title {
        font-family: "Times New Roman",serif;
        font-size: 50px;
        color: #FF7517;
      }
      .nav-title:hover{
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 992px) {
      .logo-div {
        height: 100%;
        text-align: left;
        padding: 5px 30px;
        display: flex;
        justify-content: left;

        .logo {
          height: 100%;
          width: auto;
        }

        .nav-title {
          font-family: "Times New Roman",serif;
          font-size: 40px;
          color: #FF7517;
        }
      }
    }

      .icon {
        height: 100%;
        text-align: right;
        padding-right: 50px;
        display: flex;
        justify-content: right;
        padding-bottom: 10px;

        .bar-icon {
          margin: auto 0;
          color: white;
          cursor: pointer;
          transition: 0.8s ease all;
          font-size: 25px;
        }
      }

  }
}




</style>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "NavComp",
  components: {FontAwesomeIcon},
  data() {
    return {
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      navbar: null,
      navBarMobile: null,
      sticky: null,
      isMobileNavShowen: false,
    }
  },
  created() {

    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();


  },
  mounted() {
    this.navbar = document.getElementsByClassName("navbar-color")[0]
    this.navBarMobile = document.getElementsByClassName("navbar-mobile-color")[0]
    this.sticky = this.navbar.offsetTop;
    window.addEventListener('scroll', this.scrollDetection)

  },
  methods: {

    async scrollToElement(id, route) {

      if (route === this.$router.currentRoute.name){
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
      } else {
        await new Promise((resolve) => {
          this.$router.push({name: route})
          resolve()
        });
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
      }

    },
    toogleMobileNav() {
      this.mobileNav = !this.mobileNav

      if(this.mobileNav){
        this.navBarMobile.classList.add("solid");
      } else{
        this.navBarMobile.classList.remove("solid");
      }

    },

    scrollDetection() {
      if (document.body.getBoundingClientRect().top < this.sticky) {
        this.navbar.classList.add("solid");
        this.navBarMobile.classList.add("solid");
      } else {
        this.navbar.classList.remove("solid");
        this.navBarMobile.classList.remove("solid");
      }
    },

    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 992) {
        this.mobile = true
        return;
      }
      this.mobile = false
      this.mobileNav = false
      return;
    },
  },
}
</script>