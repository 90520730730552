<script>
export default {
  name: "BenefitsComp",
  methods: {},
  components: {},
  data() {
    return {
      benefits: [
        {
          icon: "dumbbell",
          text: "Fitness auf über 2400m²",
          alt: "alt",
        },
        {
          icon: "running",
          text: "Über 100 Kurse monatlich",
          alt: "alt",
        },
        {
          icon: "fa-solid fa-glass-water-droplet",
          text: "Getränkeflatrate",
          alt: "alt",
        },
        {
          icon: "spa",
          text: "Sauna und Solarium",
          alt: "alt",
        },
        {
          icon: "parking",
          text: "Kostenlose Parkplätze",
          alt: "alt",
        },
        {
          icon: "venus",
          text: "400m² Frauenbereich",
          alt: "alt",
        },
      ],
    };
  },
};
</script>

<template>
  <section id="benefits" class="benefits-wrapper">
    <font-awesome-icon class="icons" icon="" />
    <div class="benefits">
      <div
        v-for="(benefit, index) in benefits"
        :key="index"
        class="benefit-row"
      >
        <div class="icon-wrapper">
          <font-awesome-icon :icon="benefit.icon" class="icons" />
        </div>
        <div class="benefit-content">
          <div class="benefit-list-text">{{ benefit.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.icons {
  scale: 3.5;

  color: #0d0e14;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: auto 0;
  width: 100%;
  height: 100%;
}

.benefit-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: right;
  margin: 0 auto;
  padding: 5px 0;
}

.benefit-content {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: center;
  width: 200px;
}

.benefit-list-text {
  font-family: "Archivo", sans-serif;
  font-size: 20px;
  color: #ff7517;
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.benefits-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 50px 50px;
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  width: 100px; /* Set the width to create a circle; adjust as needed */
  height: 100px; /* Set the height to match the width and create a circle */
}

@media screen and (max-width: 500px) {
  .benefits-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 50px 10px;
  }

  .benefits {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 100%;
  }

  .benefit-list-text {
    font-size: 14px;
    margin-left: 20px;
  }

  .icon-wrapper {
    width: 50px; /* Set the width to create a circle; adjust as needed */
    height: 50px;
  }

  .benefit-content {
    width: 100px;
    margin-right: 0;
  }

  .icons {
    scale: 2.2;
  }
}

@media screen and (min-width: 501px) and (max-width: 629px) {
  .benefits-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px;
  }

  .benefits {
    grid-template-columns: repeat(2, 1fr);
  }

  .benefit-list-text {
    font-size: 17px;
    margin-left: 25px;
  }

  .icon-wrapper {
    width: 80px; /* Set the width to create a circle; adjust as needed */
    height: 80px;
  }

  .benefit-content {
    width: 130px;
  }

  .icons {
    scale: 3;
  }
}

@media screen and (min-width: 630px) and (max-width: 770px) {
  .benefits {
    grid-template-columns: repeat(2, 1fr);
  }

  .benefit-list-text {
    font-size: 19px;
    margin-left: 25px;
  }

  .icon-wrapper {
    width: 90px; /* Set the width to create a circle; adjust as needed */
    height: 90px;
  }

  .benefit-content {
    width: 150px;
  }

  .icons {
    scale: 3.2;
  }
}

@media screen and (min-width: 769px) and (max-width: 899px) {
  .benefit-list-text {
    font-size: 15px;
    margin-left: 30px;
  }

  .icon-wrapper {
    width: 70px; /* Set the width to create a circle; adjust as needed */
    height: 70px;
  }

  .benefit-content {
    width: 140px;
  }

  .icons {
    scale: 3;
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .benefit-list-text {
    font-size: 17px;
    margin-left: 40px;
  }

  .icon-wrapper {
    width: 80px; /* Set the width to create a circle; adjust as needed */
    height: 80px;
  }

  .benefit-content {
    width: 150px;
  }
}
</style>
