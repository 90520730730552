<script>
export default {
  name: "PartnerComp",
}
</script>

<template>
<section class="partner container-fluid" id="partner">
  <h1 class="partner-title">PARTNER</h1>
  <div class="logos">
    <div class="logo-row row">
      <div class="col-2 logo-col"> <img src="../assets/images/partnerLogo/logo_hansefit.png" class="logo"></div>
      <div class="col-2 logo-col"> <img src="../assets/images/partnerLogo/logo_gympass.png" class="logo"></div>
      <div class="col-2 logo-col"> <img src="../assets/images/partnerLogo/logo_onefit2.png" class="logo"></div>
      <div class="col-2 logo-col"> <img src="../assets/images/partnerLogo/logo_mysports.svg" class="logo"></div>
      <div class="col-2 logo-col"> <img src="../assets/images/partnerLogo/logo_urban.png" class="logo"></div>
    </div>
  </div>

</section>
</template>

<style scoped>

@media only screen and (max-width: 600px) {
  .partner{
    background-color: #0D0E14;
    height: auto;
    width: 100%;
    margin: 0;
    padding-bottom: 10px;

    .partner-title{
      color: #FF7517;
      font-size: 30px;
      text-align: center;
      font-family: Archivo,serif;
    }
    .logo-row{
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .logo-col {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;

        .logo{
          object-fit: contain;
          height: 40px;
          width: 100px;
        }

      }
    }
  }
}

@media only screen and (min-width: 601px) {
  .partner{
    background-color: #0D0E14;
    height: auto;
    width: 100%;
    margin: 0;
    padding-bottom: 10px;

    .partner-title{
      color: #FF7517;
      font-size: 30px;
      margin: auto 0 10px;
      text-align: center;
      font-family: Archivo,serif;
    }
    .logo-row{
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .logo-col {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 5px;

        .logo{
          object-fit: contain;
          height: 40px;
          width: 100px;
        }

      }
    }
  }
}

@media only screen and (min-width: 850px) {
  .partner{
    background-color: #0D0E14;
    height: auto;
    width: 100%;
    margin: 0;
    padding-bottom: 10px;

    .partner-title{
      color: #FF7517;
      font-size: 30px;
      margin: auto 0 10px;
      text-align: center;
      font-family: Archivo,serif;
    }
    .logo-row{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .logo-col {
        height: 100%;
        width: 100%;
        .logo{
          object-fit: contain;
          height: 50px;
          width: 100%;
        }

      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .partner{
    background-color: #0D0E14;
    height: auto;
    width: 100%;
    margin: 0;
    padding-bottom: 30px;

    .partner-title{
      color: #FF7517;
      font-size: 40px;
      margin: auto 0 20px;
      text-align: center;
      font-family: Archivo,serif;
    }
    .logo-row{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .logo-col {
        height: 100%;
        width: 100%;
        .logo{
          object-fit: contain;
          height: 35px;
          width: 100%;
        }

      }
    }
  }
}


</style>