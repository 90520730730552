<script>
export default {
  name: "SaunaComp",
  data() {
    return {
      gallery: [
        {
          src: require("../assets/images/wellness/sauna9.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna3.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna1.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna6.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna7.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna2.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna4.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna5.jpg"),
        },
        {
          src: require("../assets/images/wellness/sauna8.jpg"),
        },
      ],
      modal: null,
      imageModal: null,
      isModalShowen: false,
    };
  },
  mounted() {
    this.modal = document.getElementById("modal");
    this.imageModal = document.getElementById("modalImage");
  },
  methods: {
    showModal(index) {
      this.imageModal.src = this.gallery[index].src;
      this.isModalShowen = true;
    },
    hideModal() {
      this.isModalShowen = false;
    },
  },
};
</script>

<template>
  <section id="sauna" class="sauna container-fluid">
    <div class="row">
      <div class="col-6 gallery">
        <button
          v-for="(image, index) in gallery"
          :key="index"
          class="imageButton"
          @click="showModal(index)"
        >
          <img :src="image.src" class="image" />
        </button>
      </div>
      <div class="col-6 info">
        <div class="titlebox">
          <h1 class="title-sauna">SAUNA</h1>
          <h1 class="title-sauna">ÖFFNUNGSZEITEN</h1>
        </div>
        <div class="timetabel">
          <div class="row timeRow colored">
            <div class="col-4 day">Montag</div>
            <div class="col-4 morning">09:00 - 13:00</div>
            <div class="col-4 afternoon">17:00 - 21:00</div>
          </div>
          <div class="row timeRow">
            <div class="col-4 day">Dienstag</div>
            <div class="col-4 morning"></div>
            <div class="col-4 afternoon">17:00 - 21:00</div>
          </div>
          <div class="row timeRow colored">
            <div class="col-4 day">Mittwoch</div>
            <div class="col-4 morning">09:00 - 13:00</div>
            <div class="col-4 afternoon">17:00 - 21:00</div>
          </div>
          <div class="row timeRow">
            <div class="col-4 day">Donnerstag</div>
            <div class="col-4 morning"></div>
            <div class="col-4 afternoon">17:00 - 21:00</div>
          </div>
          <div class="row timeRow colored">
            <div class="col-4 day">Freitag</div>
            <div class="col-4 morning">09:00 - 13:00</div>
            <div class="col-4 afternoon">17:00 - 21:00</div>
          </div>
          <div class="row timeRow">
            <div class="col-4 day">Samstag</div>
            <div class="col-4 morning"></div>
            <div class="col-4 afternoon">17:00 - 22:00</div>
          </div>
          <div class="row timeRow colored">
            <div class="col-4 day">Sonntag</div>
            <div class="col-4 morning"></div>
            <div class="col-4 afternoon">12:00 - 18:00</div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isModalShowen" id="modal" class="modal">
      <button class="close" @click="hideModal">&times;</button>
      <img id="modalImage" :src="gallery[0].src" class="modal-content" />
    </div>
  </section>
</template>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

.modal-content {
  top: 20%;
  margin: auto;
  display: block;
  width: 80%;
  max-width: 90vh;
}

.close {
  all: unset;
  position: absolute;
  top: 10%;
  right: 35px;
  color: #f1f1f1;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
      height: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 100px 100px 100px;
        grid-template-columns: 100px 100px 100px;
        grid-gap: 15px;
        justify-content: center;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        margin-top: 50px;
        height: 100%;
        max-width: 100%;
        display: block;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 25px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
            font-size: 30px;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            .day {
              font-size: 14px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 10px;
            }

            .morning {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 100px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 120px 120px 120px;
        grid-template-columns: 120px 120px 120px;
        grid-gap: 15px;
        justify-content: center;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        margin-top: 50px;
        height: 100%;
        max-width: 100%;
        display: block;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 25px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
            font-size: 30px;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            .day {
              font-size: 14px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 10px;
            }

            .morning {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 850px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 50px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      vertical-align: center;
      width: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 100px 100px 100px;
        grid-template-columns: 100px 100px 100px;
        grid-gap: 15px;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        height: 100%;
        display: block;
        margin: 0;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 25px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
            font-size: 30px;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 30px;
            width: 100%;
            margin: auto;

            .day {
              font-size: 14px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 30px;
            }

            .morning {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 12px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 70px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      vertical-align: center;
      width: 100%;
      height: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 140px 140px 140px;
        grid-template-columns: 140px 140px 140px;
        grid-gap: 15px;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        height: 100%;
        display: block;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 50px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 40px;
            width: 80%;
            margin: auto;

            .day {
              font-size: 18px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 30px;
            }

            .morning {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 250px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      vertical-align: center;
      width: 100%;
      height: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 140px 140px 140px;
        grid-template-columns: 140px 140px 140px;
        grid-gap: 15px;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        height: 100%;
        display: block;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 50px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 40px;
            width: 80%;
            margin: auto;

            .day {
              font-size: 18px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 30px;
            }

            .morning {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .sauna {
    margin: 0 auto;
    padding: 50px 250px;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;

    .row {
      display: flex;
      justify-content: center;
      vertical-align: center;
      width: 100%;
      height: 100%;

      .gallery {
        display: grid;
        grid-template-rows: 160px 160px 160px;
        grid-template-columns: 160px 160px 160px;
        grid-gap: 15px;

        .imageButton {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        height: 100%;
        display: block;

        .titlebox {
          width: 100%;
          height: 20%;
          margin-bottom: 50px;

          .title-sauna {
            color: #ff7517;
            font-family: "Alfa Slab One", Serif;
            margin: 5px auto;
            text-align: center;
            font-size: 40px;
          }
        }

        .timetabel {
          .colored {
            background-color: #333439;
          }

          .timeRow {
            height: 40px;
            width: 60%;
            margin: auto;

            .day {
              font-size: 18px;
              margin: auto 0;
              font-family: Archivo;
              font-weight: bold;
              color: white;
              text-align: left;
              padding-left: 30px;
            }

            .morning {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: center;
            }

            .afternoon {
              margin: auto 0;
              font-size: 15px;
              font-family: Archivo;
              color: white;
              text-align: right;
              padding-right: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
