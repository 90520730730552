<script>
export default {
  data() {
    return {
      benefits: [
        "Jumping Fitness",
        "Ausdauertraining",
        "Frauenbereich",
        "Getränkeflat",
        "Sauna",
        "Körperanalyse",
        "Gesundheitsplanung",
        "Trainingsberatung",
        "Free WiFi",
        "Parkplätze",
      ],
    };
  },
};
</script>

<template>
  <div>
    <h1 class="benefits-title">Zusätzliche Vorteile</h1>
    <div class="benefits-grid">
      <div v-for="(benefit, index) in benefits" :key="index" class="benefit">
        <img
          alt="Icon"
          class="caprice-logo"
          src="@/assets/images/logo/caprice_logo.png"
        />
        <h3 class="benefit-list-text">{{ benefit }}</h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.benefits-title {
  margin-top: 5vw;
  margin-bottom: 5vw;
  text-align: center;
  color: #ff7517;
  font-family: "Alfa Slab One", serif;
  font-size: 3vw;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  width: 90vw;
  margin: 0 auto;
  padding-bottom: 150px;
}

.benefit {
  display: flex;
  align-items: center;
}

.caprice-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.benefit-list-text {
  font-family: "Archivo", sans-serif;
  font-size: 30px;
  color: #ff7517;
  margin: 0;
}

@media screen and (max-width: 1900px) {
  .benefits-title {
    font-size: 45px;
  }

  .benefits-grid {
    padding-left: 10vw;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  .benefit-list-text {
    font-size: 2vw;
  }
}

@media screen and (max-width: 900px) {
  .benefit-list-text {
    font-size: 18px;
  }
}

@media screen and (min-width: 420px) and (max-width: 560px) {
  .benefit-list-text {
    font-size: 15px;
  }

  .caprice-logo {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 420px) {
  .benefits-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}
</style>
